import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { TitleSection, TeamBlock, MetaData } from '../components';
import JobCard from '../components/JobCard';
import PersonCard from '../components/PersonCard';
import PartnerCard from '../components/PartnerCard';
import ThreeCol from '../components/ThreeCol';

type Props = {
	data: any;
	context?: {
		interest?: string;
	};
	location?: { pathname?: string };
};

const Container = styled.div`
	min-height: 85vh;
`;
const PartnersPage = (props: Props) => {
	const partners: any[] = props.data.allWordpressAcfPartner.nodes ?? [];

	const title =
		partners.length > 0 ? `Our Partners` : `No partners up right now`;

	return (
		<React.Fragment>
			<MetaData title={title} description={'Our trusted partners'} />
			<Container>
				<TitleSection
					padTop
					title={title}
					containerSize="sm"
					color="grey"
					content={
						<Fragment>
							<ThreeCol>
								{partners.map((partner: any) => (
									<PartnerCard
										link={`/partners/${partner.id}`}
										id={partner.id}
										name={partner?.acf?.title ?? ''}
										image={{
											source_url: partner.acf?.image?.source_url ?? '',
											alt_text: 'image',
										}}
										role={partner.acf?.short_description ?? ''}
										bio={partner.acf?.description ?? ''}
									/>
								))}
							</ThreeCol>
						</Fragment>
					}
				/>
			</Container>
		</React.Fragment>
	);
};

export const query = graphql`
	query ListPartnersForPage {
		allWordpressAcfPartner {
			nodes {
				id
				acf {
					description
					image {
						source_url
					}
					short_description
					title
				}
			}
		}
	}
`;

export default PartnersPage;
